.compare-sidebar {
  flex-shrink: 0;
  width: 225px;
  display: flex;
  flex-direction: column;
  @include xs {
    width: 100%; }

  &__head {
    height: 460px;
    flex-shrink: 0;
    margin: 20px 0 0;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $G-3;
    border-right: 1px solid $G-3;
    @include xs {
      height: auto !important;
      display: none; } }

  &__body {
    padding-top: 20px;
    border-right: 1px solid $G-3;
    height: 100%;
    @include xs {
      display: none; } } }


.compare-sidebar-item {
  padding: 4px 0 2px;
  font-size: 13px;
  line-height: 1.54;
  @include semi; }
