@import "variables/_variables-dir";
@import "helpers/_helpers-dir";

@import "containers/compare/compare-groups";
@import "containers/compare/compare-sidebar";
@import "containers/compare/compare-clear";
@import "containers/compare/compare-products";
@import "containers/compare/compare-sticky";


.compare-page {
  padding-bottom: 97px;
  padding-top: 20px;

  &__title {
    padding: 18px 0 24px;
    font-size: 28px;
    @include bold;
    @include xs {
      font-size: 24px; } }

  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: $red;
    text-transform: uppercase;
    text-decoration: none;
    @include bold;
    font-size: 11px;

    &:hover {
      text-decoration: underline; }

    .icon {
      margin-right: 8px;
      font-size: 16px;
      fill: $red; } } }


.compare-table {
  display: flex;
  @include xs {
    flex-direction: column-reverse;
    margin-left: -20px;
    margin-right: -20px; } }

.compare-row-hover {
  position: fixed;
  z-index: 99;
  width: 100vw;
  left: 0;
  transition: height $easing-default, top $easing-default;
  transform-origin: center center;
  background-color: rgba(198, 67, 68, 0.1);
  pointer-events: none; }

.compare-mob-head {
  height: auto !important;
  padding: 12px 16px 16px;
  margin-bottom: 50px;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;

  @include xsUp {
    display: none; }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .compare-clear {
    @include xs {
      margin-bottom: 14px; } }

  .radio {
    &__circle {
      width: 16px;
      height: 16px; }

    &__label {
      padding-left: 24px; } }
  .radio__input:checked~.radio__circle:before {
    width: 8px;
    height: 8px; }

  .radio+.radio {
    margin: 0; } }
