.compare-clear {
  position: relative;
  display: inline-flex;
  padding-left: 14px;
  background-color: transparent;
  border: 0;
  font-size: 14px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 55%;
    height: 1px;
    width: 8px;
    background-color: #000000; }

  &:before {
    transform: translateY(-50%) rotate(45deg); }
  &:after {
    transform: translateY(-50%) rotate(-45deg); }

  &:hover {
    color: $red;
    &:before,
    &:after {
      background-color: $red; } } }
