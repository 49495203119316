.compare-products {
  position: relative;
  max-width: 915px;
  @include r(1240) {
    width: calc(100% - 225px); }
  @include xs {
    width: 100%; }

  &__arrow {
    position: absolute;
    top: 100px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    cursor: pointer;
    transition: opacity .2s ease, transform .2s ease;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
    background: $G-0;
    @include xs {
      width: 34px;
      height: 34px; }

    &:hover {
      svg {
        fill: $red; } }

    &.swiper-button-disabled,
    &.is-hidden {
      display: none; }

    svg {
      fill: $G-5;
      font-size: 24px;
      @include xs {
        font-size: 16px; } }

    &--prev {
      left: -27px;
      @include xs {
        left: 6px; } }

    &--next {
      right: -27px;
      @include r(1240) {
        right: -7px; }
      @include xs {
        right: 7px; }

      svg {
        transform: rotate(180deg); } } }


  .swiper-wrapper {
    box-sizing: border-box; }

  .swiper-container {
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      width: 62px;
      height: 100%;
      pointer-events: none; }

    &:before {
      right: 0;
      background: linear-gradient(-90deg, #FFFFFF 21.59%, rgba(255, 255, 255, 0) 100%); } } }


.compare-products-item {
  width: 220px;

  &__head {
    position: relative;
    margin-top: 20px;
    padding: 0 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $G-3;
    border-right: 1px solid $G-3;

    &.is-sticky {
      position: fixed;
      top: 0; } }

  &__body {
    padding: 20px 12px 0;
    border-right: 1px solid $G-3; }

  &__text {
    padding: 4px 12px 2px 0;
    font-size: 13px;
    line-height: 1.54;
    & + & {
      @include xs {
        margin-top: 20px; } }

    a {
      text-decoration: underline;
      color: #000000;

      &:hover {
        color: $red;
        text-decoration: none; } } }

  &__row-title {
    display: block;
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.54;
    text-align: left;
    color: #000000;

    @include xsUp {
      display: none; } }

  &__delete {
    display: block;
    position: absolute;
    z-index: 3;
    top: 7px;
    right: 15px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000000;
      pointer-events: none; }

    &:before {
      transform: rotate(45deg); }

    &:after {
      transform: rotate(-45deg); }

    &:hover {
      &:before,
      &:after {
        background-color: $red; } } } }
