.compare-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100%;
  background: $G-0;
  box-shadow: $sh-2;

  transition: transform .5s ease-in-out;
  transform: translateY(-100%);

  &.is-sticky {
    transform: translateY(0); }

  &__inner {
    display: flex; }

  &__aside {
    flex-shrink: 0;
    @include xs {
      display: none; } }

  &__prods {
    width: calc(100% - 225px);
    @include xs {
      width: 100%; } }

  .compare-products {
    width: 100%;
    max-width: 100%; }

  .container {
    @include xs {
      padding: 0; } }



  .compare-products-item {
    height: auto;

    &__head {
      height: 100%;
      margin-top: 16px; }

    &__delete {
      top: 0; } }

  .compare-sidebar {
    height: 100%;

    &__head {
      height: calc(100% - 20px);
      padding: 0 0 20px;
      margin: 20px 0 0;
      border: 0;
      justify-content: flex-end;
      border-right: 1px solid $G-3; } }

  .product-tile {

    &__image {
      max-width: 100px;
      margin-left: 10px;

      img {
        height: auto; } }

    &__title {
      margin-bottom: 0; } }

  .product-tile--compare .product-tile__label {
    top: 10px; }

  .compare-products__arrow {
    top: 72px;
    @include xs {
      right: 10px; } } }
