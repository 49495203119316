.compare-groups {

  .container {
    @include xs {
      padding: 0; } }

  &__inner {
    position: relative; }

  &__slider-container {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      width: 62px;
      height: 100%;
      opacity: 0;
      transition: opacity $easing-default; }

    &:before {
      left: 0;
      background: linear-gradient(90deg, #FFFFFF 21.59%, rgba(255, 255, 255, 0) 100%); }

    &:after {
      right: 0;
      background: linear-gradient(-90deg, #FFFFFF 21.59%, rgba(255, 255, 255, 0) 100%); }

    &.is-end {
      &:before {
        opacity: 1; } }

    &.is-start {
      &:after {
        opacity: 1; } } }

  &__item {
    position: relative;
    padding: 20px 24px;
    width: auto;
    height: 80px;
    background-color: $G-2;
    border: 1px solid $G-3;
    color: $G-5;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    transition: color $easing-default;

    &.is-active {
      background-color: transparent;
      border-color: transparent;
      color: #000000;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background-color: #000000; } }

    &:not(.is-active):hover {
      color: $red;

      .compare-groups__amount {
        color: $red; } }

    &:last-child {
      margin-right: 40px; } }

  &__title {
    @include bold; }

  &__amount {
    color: $G-4;
    font-size: 14px;
    transition: color $easing-default; }

  &__arrow {
    position: absolute;
    right: 0;
    z-index: 3;
    font-size: 24px;
    cursor: pointer;

    &--next {
      transform: scale(-1);
      top: 14px;
      @include xs {
        top: -35px;
        right: 20px; } }

    &--prev {
      top: 44px;
      @include xs {
        top: -35px;
        right: 60px; } }

    &.swiper-button-disabled {
      svg {
        fill: $G-3; } }

    svg {
      display: block; } }

  .swiper-wrapper {
    box-sizing: border-box; } }

